/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@3.4.1/dist/css/bootstrap.min.css
 * - /npm/toastr@2.1.4/build/toastr.min.css
 * - /npm/slick-carousel@1.8.1/slick/slick.min.css
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 * - /npm/jqueryui@1.11.1/jquery-ui.structure.min.css
 * - /npm/jqueryui@1.11.1/jquery-ui.theme.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
